module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SRE NEXT 2025","short_name":"SRE NEXT 2025","description":"SRE NEXTとは、信頼性に関するプラクティスに深い関心を持つエンジニアのためのコミュニティベースのカンファレンスです。","start_url":"/2025/","background_color":"#fff","theme_color":"#3290e6","display":"standalone","icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4d4a4f48243818029df95079ed51f36c"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
